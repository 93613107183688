<template>
  <div>
    <div class="message">
      To livestream from your phone, you must enable Camera and Microphone access for clouthub.com.
    </div>
    <div v-if="deviceType==='android'">
      <div class="images">
        <div class="message">Step 1: Open Chrome, then go to Site Setting from menu</div>
        <img class="image" src="../../assets/android-1.jpeg" />
      </div>
      <div class="images">
        <div class="message">Step 2: Ensure your phone is set to 'Ask First' for camera, and that clouthub.com is not on your blocklist</div>
        <img class="image" src="../../assets/android-2.jpeg" />
      </div>
    </div>
    <div v-if="deviceType==='ios'">
      <div class="images">
        <div class="message">Step 1: Go to the Settings app and select Safari</div>
        <img class="image" src="../../assets/ios-1.png" />
      </div>
      <div class="images">
        <div class="message">Step 2: Select Camera and Microphone</div>
        <img class="image" src="../../assets/ios-2.png" />
      </div>
      <div class="images">
        <div class="message">Step 3: Use 'Ask' or 'Allow' options, then re-open this window</div>
        <img class="image" src="../../assets/ios-3.png" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      deviceType: null
    }
  },
  mounted() {
    const userAgent = window.navigator.userAgent
    const iOS = ( navigator.userAgent.match(/(iPad|iPhone|iPod)/i) ? true : false );
    console.log('userAgent', userAgent);
    console.log('iOS', iOS);
    
    this.deviceType = iOS ? 'ios' : 'android';
    console.log('this.deviceType', this.deviceType);
    
  }
}
</script>

<style scoped>
.images{
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.image{
  margin-top: 10px;
  width: 200px;
}
.message
{
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
