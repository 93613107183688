import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import axios from 'axios';

const qs = window.location.search

function getHeaders() {
  let params = (new URL(document.location)).searchParams;
  let token = params.get('token');
  return {
    Authorization: `Bearer ${token}`,
  };
}

const httpLink = new HttpLink({
  uri: 'https://graph.clouthub.com', // PROD
  // uri: 'https://graph-dev.clouthub.com', // DEV
  headers: getHeaders(),
});


// Log any GraphQL errors or network error that occurred
const errorLink = onError((err) => {
  try {
    let payload = {};
    payload.token = localStorage.access_token;
    payload.app = 'desktop';
    payload.graphQLErrors = err.graphQLErrors && err.graphQLErrors.map((e) => (e.message));
    if (err.networkError) {
      payload.networkError = {
        statusCode: err.networkError.statusCode,
        message: err.networkError.message,
      };
    }
    if (err.operation) {
      payload.operationName = err.operation.operationName;
      payload.variables = err.operation.variables;
      if (err.operation.query) {
        payload.querySrc = err.operation.query.loc.source.body;
      }
    }
    axios.post('https://graph.clouthub.com/gqlerror', payload);
  } catch (err) {

  }
});

export default new ApolloClient({
  link: from([
    errorLink,
    httpLink,
  ]),
  cache: new InMemoryCache(),
});
