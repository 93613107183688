import Vue from 'vue';
import VueRouter from 'vue-router';
// import BrowserStreaming from '@/views/BrowserStreamingNew.vue';
import BrowserStreaming from '@/views/BrowserStreaming.vue';

Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    component: BrowserStreaming,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
