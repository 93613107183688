<template>
  <div>
    <div v-if="!isAudioPermissionOK  || !isVideoPermissionOK">
      <no-permission-view />
    </div>
    <div v-else>
      <div v-if="isLoading"> Loading.... </div>
      <div v-if="localStream || previewStream">
        <div>
          <label>Camera</label>
          <select label="Camera"
            v-model="selectedVideoDevice"
          >
            <option v-for="option in videoDevices" :key="option.value" :value="option.value">{{option.label}}</option>
          </select>
        </div>
        <div class="">
          <label>Mic</label>
          <select :options="audioDevices" label="Mic"
            v-model="selectedAudioDevice"
          >
            <option v-for="option in audioDevices" :key="option.value" :value="option.value">{{option.label}}</option>
          </select>
        </div>
        <button
          @click = "startLive"
          class="btn"
          v-if="isPreviewStream"
        >
          Go Live
        </button>
        <button
          @click = "leave"
          class="btn"
          v-if="isLiveStream"
        >
          Stop Streaming
        </button>
        <app-web-live-stream :stream="isPreviewStream? previewStream :localStream" />
      </div>
    </div>
  </div>
</template>

<script>
import $auth from '@/services/auth';
import AgoraRTC from 'agora-rtc-sdk';
import GetLiveStreamAgoraToken from '@/gql/GetLiveStreamAgoraToken.gql';
import AppWebLiveStream from './WebLiveStream.vue';
// const AGORA_APP_ID = 'fbef7dbf2c3346daaef51f3ecc105405'; // DEV
const AGORA_APP_ID = 'c15bb6b2718e4bf19dbaa64482b7df24'; // PROD
import NoPermissionView from './NoPermissionView.vue'
export default {
  components: {
    AppWebLiveStream,
    NoPermissionView
  },
  data() {
    let params = (new URL(document.location)).searchParams;
    let streamKey = params.get('key');

    let cameraWidth = 1280;
    let cameraHeight = 1280;
    let ratio = 1;

    if (screen.width > screen.height) {
      ratio = (screen.height / screen.width);
      cameraHeight = Math.floor(ratio * 1280);
    } else {
      ratio = (screen.width / screen.height);
      cameraWidth = Math.floor(ratio * 1280);
    }
    return {
      publishSettings: {
        audio: true,
        video: true,
      },
      userId: $auth.getUserId(),
      streamKey: streamKey,
      token: this.$route.query.token,
      isLoading: false,
      localStream: null,
      previewStream: null,
      client: null,
      baseStreamUrl: 'rtmps://global-live.mux.com:443/app',
      cameraWidth: cameraWidth,
      cameraHeight: cameraHeight,
      audioDevices: [],
      videoDevices: [],
      selectedAudioDevice: null,
      selectedVideoDevice: null,
      isAudioPermissionOK: true,
      isVideoPermissionOK: true,
      isPreviewStream: false,
      isLiveStream: false
    }
  },
  watch: {
    selectedVideoDevice() {
      this.switchDevice('video', this.selectedVideoDevice);
    },
    selectedAudioDevice() {
      this.switchDevice('audio', this.selectedAudioDevice);
    },
  },
  mounted() {
    this.listDevices()
  },
  methods: {
    switchDevice(type, deviceId) {
      if (this.localStream || this.previewStream && deviceId) {
        if(this.isPreviewStream) {
          this.previewStream.getVideoTrack().stop();
          this.previewStream.switchDevice(type, deviceId);
        }
        else {
          this.localStream.getVideoTrack().stop();
          this.localStream.switchDevice(type, deviceId);
        }
      }
    },
    async listDevices() {
      const tempAudioStream = AgoraRTC.createStream({ audio: true, video: false});
      const tempVideoStream = AgoraRTC.createStream({ audio: false, video: true});
        const audioPermissionOK = new Promise(resolve => {
          tempAudioStream.init(() => resolve(null), (e) => resolve(e));
        });
        const videoPermissionOK = new Promise(resolve => {
          tempVideoStream.init(() => resolve(null), (e) => resolve(e));
        });
        return Promise.all([audioPermissionOK, videoPermissionOK]).then(res => {
          if (res[0] !== null) {
            if(res[0].msg == "NotAllowedError") this.isAudioPermissionOK = false
          } else this.isAudioPermissionOK = true
          if (res[1] !== null) {
            if(res[1].msg == "NotAllowedError") this.isVideoPermissionOK= false
          } else this.isVideoPermissionOK= true

          tempAudioStream.close();
          tempVideoStream.close();
          if(this.isVideoPermissionOK && this.isAudioPermissionOK) {
            AgoraRTC.getDevices((devices) => {
              this.audioDevices = devices.filter((device) => (device.kind === 'audioinput')).map((device) => ({
                label: device.label,
                value: device.deviceId,
              }));
              this.videoDevices = devices.filter((device) => (device.kind === 'videoinput')).map((device) => ({
                label: device.label,
                value: device.deviceId,
              }));
              this.selectedAudioDevice = this.audioDevices[0] && this.audioDevices[0].value;
              this.selectedVideoDevice = this.videoDevices[0] && this.videoDevices[0].value;
              this.startPreview();
            });
          }
        });
    },
    async startPreview() {
      try {
        this.isPreviewStream = true;
        this.isLoading = true

        // await this.joinChannel();
        await this.createStream();
        this.isLoading = false;
      } catch (err) {
        alert('Error: ' + err.message);
      }
    },
    async startLive() {
      try {
        if (this.previewStream.isPlaying()) {
          this.previewStream.stop();
        }
        this.previewStream.close();
        this.previewStream = null;
        this.isLoading = true
        this.isPreviewStream = false;
        // await this.setClientRole();
        await this.joinChannel();
        await this.setClientRole();
        await this.publishStream();
        this.isLoading = false;
        this.isLiveStream = true;
        this.transcode();
        this.switchDevice('video', this.selectedVideoDevice);
        this.switchDevice('audio', this.selectedAudioDevice);
      } catch (err) {
        alert('Agora Connection Error: ' + JSON.stringify(err));
      }
    },
    setClientRole() {
      return new Promise((resolve) => {
        this.client.setClientRole('host', resolve);
      });
    },
    transcode() {
      const url = `rtmps://global-live.mux.com:443/app/${this.streamKey}`
        const transcodingConfig = {
            width: this.cameraWidth, // 1280,
            height: this.cameraHeight, // 1280,
            videoBitrate: 1130,
            videoFramerate: 24,
            lowLatency: false,
            audioSampleRate: AgoraRTC.AUDIO_SAMPLE_RATE_48000,
            audioBitrate: 48,
            audioChannels: 1,
            videoGop: 30,
            videoCodecProfile: AgoraRTC.VIDEO_CODEC_PROFILE_HIGH,
            userCount: 1,
            backgroundColor: 0x000000,
            transcodingUsers: [
              {
                uid: this.userId,
                alpha: 1,
                width: this.cameraWidth,
                height: this.cameraHeight,
                zOrder: 1,
                x: 0,
                y: 0
              }
            ],
        }
      this.client.setLiveTranscoding(transcodingConfig)
      this.client.startLiveStreaming(url, true)
    },
    async publishStream() {

      return new Promise((resolve, reject) => {

        const localStream = AgoraRTC.createStream({
          streamID: this.userId,
          audio: this.publishSettings.audio,
          video: this.publishSettings.video,
          // screen: this.publishSettings.screen,
          // audioSource: this.selectedAudioDevice,
          // videoSource: this.selectedVideoDevice,
        });

        localStream.setVideoEncoderConfiguration({
          resolution: {
            width: this.cameraWidth,
            height: this.cameraHeight,
          },
          orientationMode: 'ADAPTIVE'
        });
        localStream.setVideoProfile('720p_1');
        localStream.init(() => {
          this.localStream = localStream;
            this.client.publish(localStream, (err) => {
                if (err) {
                  reject(err);
                }
              }, (err) => {
                console.log('FAILED', err)
              });
            resolve(localStream);
        }, (err) => {
          alert('Unable to publish: ' + err.message);
          if (err.info) {
            alert(err.info);
          }
          reject(err);
        });
      });
    },
    async createStream() {
      return new Promise((resolve, reject) => {
        const previewStream = AgoraRTC.createStream({
          streamID: this.userId + '.previewstream',
          audio: this.publishSettings.audio,
          video: this.publishSettings.video,
        });
        previewStream.setVideoEncoderConfiguration({
          resolution: {
            width: this.cameraWidth,
            height: this.cameraHeight,
          },
          orientationMode: 'ADAPTIVE'
        });
        previewStream.setVideoProfile('720p_1');
        previewStream.init(() => {
          this.previewStream = previewStream;
            resolve(previewStream);
        }, (err) => {
          alert('Unable to publish: ' + err.message);
          if (err.info) {
            alert(err.info);
          }
          reject(err);
        });
      });
    },
    async leave() {
      console.log('Leave 1');
      return new Promise((resolve, reject) => {
        console.log('Unpublish 1');
        this.unpublish();
        this.client.leave(() => {
          console.log('Leave 2');
          this.client = null;
          alert('Your video will be available in your channel soon. Please close this window.');
          window.close();
          resolve();
        }, (err) => {
          console.log('Failed to leave', err);
          reject(err);
        });
      });
    },
    async unpublish() {
      this.stopStream()
      return new Promise((resolve, reject) => {
        if (this.localStream) {
          this.client.unpublish(this.localStream, (err) => {
            if (err) {
              reject(err)
            } else {
              resolve();
            }
          });
          if (this.localStream.isPlaying()) {
            this.localStream.stop();
          }
          this.localStream.close();
          this.localStream = null;
        }
        resolve();
      });
    },
    stopStream() {
      const url = `${this.baseStreamUrl}/${this.streamKey}`
      this.client.stopLiveStreaming(url)
    },

    async joinChannel() {
      // this.client = await AgoraRTC.createClient({mode: 'live', codec: 'vp8'})
      // await AgoraRTC.Logger.setLogLevel(AgoraRTC.Logger.WARNING);
      // const numericUserId = this.userId.match(/\d/g).join('').substring(0, 8) * 1;
      // const randomUid = numericUserId.toString();
      const resp = await this.$apollo.query({
        query: GetLiveStreamAgoraToken,
        variables: {
          liveStreamKey: this.streamKey,
        },
      });
      return new Promise((resolve, reject) => {
        const token = resp.data.token;
        const channel = this.streamKey;
        this.client = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });
        AgoraRTC.Logger.setLogLevel(AgoraRTC.Logger.WARNING);
        this.client.init(AGORA_APP_ID, () => {
          this.client.join(token, channel, this.userId, resolve, (err) => {
            console.log('error in join ', err);
          });
        });
      });
    },
  }
}
</script>

<style>
.btn{
  background-color: #FF2E2E;
  border-radius: 33px;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
}
select
{
  width: 100%;
  padding: 8px;
}
label
{
  margin-top: 10px;
  display: block;
}

</style>
