import jsonwebtoken from 'jsonwebtoken';
import $apollo from './apollo';

let userId = null;
const getUserId = () => {
  if (!userId) {
    let params = (new URL(document.location)).searchParams;
    let token = params.get('token');

    const payload = jsonwebtoken.decode(token);
    userId = payload.id;
  }
  return userId;
};


export default {
  getUserId
};
